import React from "react";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button, Paper, Grid, Chip, Link } from "@material-ui/core";
import _ from "lodash";
import { RouteChildrenProps } from "react-router";
import { Formik, Form } from "formik";

import Pagination, { defaultPageSizesOptions } from "../../components/pagination";
import { FilterList, FilterText } from "../../components/filter-box";
import { WrappedVirtualizedTable } from "./../../components/table";
import { FilterValue, FilterValues } from "./../../components/filter-box";
import { computeYearValues } from './../../components/textfield';
import * as Filters from "./_filters";
import { columns } from "./_columns";
import {
  getEmptyFilters,
  getInitialFilters,
  getInitialSort,
  getInitialPageSize,
  getAppliedFilters,
  idParser
} from "./_utils";
import { getRowsFromAPI, Data } from "./_fetch";

const useStyles = makeStyles({
  root: {
    padding: "0 5px",
    ["@media (min-width:700px)"]: {
      padding: "0 30px"
    }
  },
  chip: {
    height: "25px !important",
    margin: "1px 2px"
  },
  chipIcon: {
    margin: "0 2px 0 -8px !important"
  },
  table: {
    height: 630,
    width: "100%"
  },
  copyright1: {
    marginTop: "15px !important"
  }
});

export interface Filter {
  location: FilterValues;
  legalForm: FilterValues;
  year: FilterValues;
  format: FilterValues;
  type: FilterValues;
}

interface TableSort {
  sortBy: string;
  sortDirection: "ASC" | "DESC" | undefined;
}

export const Dashboard: React.FC<RouteChildrenProps> = ({ history }) => {
  const classes = useStyles();

  // hook for storing selected sort in table header
  const [tableSort, setTableSort] = React.useState<TableSort>(getInitialSort());
  const [data, setData] = React.useState<Data>({ count: 0, rows: []});
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(getInitialPageSize());
  const filters = getInitialFilters();

  const refreshRows = async (page: number, pageSize: number, params?: FilterValue[]) => {
    const data: Data = await getRowsFromAPI(page, pageSize, params);
    setData(data);
  };
  React.useEffect(() => {
    const appliedFilters: FilterValue[] = getAppliedFilters(filters);
    refreshRows(page, pageSize, appliedFilters);
  }, []);

  // change row height for mobile
  const mobile = useMediaQuery("(max-width:630px)");

  const pagination = (appliedFilters: FilterValue[]) => (
    <Pagination
      pageSizesOptions={defaultPageSizesOptions}
      count={data.count}
      page={page}
      pageSize={pageSize}
      onChangePage={(_, newPage) => {
        setPage(newPage);
        refreshRows(newPage, pageSize, appliedFilters);
      }}
      onChangeRowsPerPage={(e) => { 
        setPage(0);
        setPageSize(+e.target.value);
        window.localStorage.setItem("pagesize", e.target.value)
        refreshRows(0, +e.target.value, appliedFilters);
      }}
    />
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* formik package for management forms */}
        {/* more info: https://jaredpalmer.com/formik/docs/overview */}
        <Formik<Filter>
          initialValues={filters}
          onSubmit={(values, { setSubmitting }) => {
            const appliedFilters = getAppliedFilters(values);
            // console.log('SUBMIT filters: ', appliedFilters);
            // console.log('SUBMIT sort   : ', tableSort);

            setPage(0)
            refreshRows(0, pageSize, appliedFilters);

            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, setValues }) => {
            // get checked filters
            const appliedFilters = getAppliedFilters(values);
            return (
              <Form>
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <FilterList
                      label="Sídlo dle rejstříkového soudu"
                      name="location"
                      array={Filters.location}
                    />
                    <FilterList
                      label="Právní forma"
                      name="legalForm"
                      array={Filters.legalForm}
                    />
                    <FilterText
                      label="Období"
                      name="year"
                      array={Filters.year}
                    />
                    <FilterList
                      label="Formáty"
                      name="format"
                      array={Filters.format}
                    />
                    <FilterList
                      label="Typ výpisu"
                      name="type"
                      array={Filters.type}
                    />
                  </Grid>

                  <Grid item xs={12} md={9}>
                    <div className={classes.root}>
                      <Typography variant="h6" gutterBottom>
                        Vyhledávání datových sad
                      </Typography>
                      <Typography variant="button" gutterBottom>
                        Klíčová slova:{" "}
                        {/* TODO: separate next statement into own component <KeyWords values={values} /> */}
                        {/* - appliedFilters will be computed inside of this component */}
                        {appliedFilters.filter(f => f.textfield).map(
                          f => f.label.split(',').filter(part => /\d+(-\d+){0,1}/.test(part)).map(part => (
                          <Chip
                            label={part}
                            key={part}
                            color="secondary"
                            onDelete={async () => {
                              const removePart = (str: string): string => str.replace(new RegExp(`,${part}$|${part},?`), '')
                              const computedValuesFn = (filter: FilterValue) => computeYearValues(removePart(filter.label)).join(',') //inOperationSeparator !
                              
                              // uncheck field from formik values
                              await setFieldValue(`${f.tag}.TEXT`, { 
                                ...f, 
                                label: removePart(f.label),
                                value: computedValuesFn(f)
                              });

                              // TODO: separate into util
                              const updatedValues = _.update(values, `${f.tag}.TEXT`, o => ({ 
                                ...o, 
                                label: removePart(o.label),
                                value: computedValuesFn(o)
                              }) );

                              window.localStorage.setItem("filters", JSON.stringify(updatedValues) );
                            }}
                            classes={{
                              deleteIcon: classes.chipIcon,
                              root: classes.chip
                            }}
                          />
                        )))}
                        {appliedFilters.filter(f => !f.textfield).map(f => (
                          <Chip
                            label={f.label}
                            key={f.label}
                            color="secondary"
                            onDelete={async () => {
                              // uncheck field from formik values
                              await setFieldValue(`${f.tag}.${f.value}`, { ...f, checked: !f.checked });

                              // TODO: separate into util
                              const updatedValues = _.update(values, `${f.tag}.${f.value}`, o => ({ ...o, checked: !o.checked }) );

                              window.localStorage.setItem("filters", JSON.stringify(updatedValues) );
                            }}
                            classes={{
                              deleteIcon: classes.chipIcon,
                              root: classes.chip
                            }}
                          />
                        ))}
                      </Typography>

                      <Typography variant="button" gutterBottom>
                        <Button
                          type="submit"
                          size="medium"
                          variant="outlined"
                          color="secondary"
                        >
                          HLEDAT DLE KLÍČOVÝCH SLOV
                        </Button>
                        &nbsp;
                        <Button
                          type="button"
                          size="medium"
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            setValues(getEmptyFilters());
                            window.localStorage.setItem(
                              "filters",
                              JSON.stringify(getEmptyFilters())
                            );
                          }}
                        >
                          ZRUŠIT KLÍČOVÁ SLOVA
                        </Button>
                      </Typography>
                      {/* <Typography variant="button" gutterBottom>
                        Nalezeno {rows.length} datových sad
                      </Typography> */}

                      {pagination(appliedFilters)}

                      <Paper className={classes.table}>
                        <WrappedVirtualizedTable
                          rowCount={data.rows.length}
                          rowClassName=""
                          rowGetter={({ index }) => data.rows[index]}
                          history={history}
                          formatter={id => idParser(id)}
                          headerHeight={mobile ? 110 : 56}
                          rowHeight={mobile ? 110 : 56}
                          sortBy={tableSort.sortBy}
                          sortDirection={tableSort.sortDirection}
                          sort={({ sortBy, sortDirection }) => {
                            setTableSort({ sortBy, sortDirection });

                            window.localStorage.setItem(
                              "sort",
                              JSON.stringify({ sortBy, sortDirection })
                            );
                          }}
                          columns={columns}
                        />
                      </Paper>

                      {pagination(appliedFilters)}
                    </div>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};
