import React from "react";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    flexShrink: 0,
    marginLeft: '15px',
  },
});

interface MuiPaginationProps {
  count: number,
  pageSizesOptions: number[],
  page: number,
  pageSize: number,
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void,
  onChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
}

export const defaultPageSizesOptions = [10, 50, 100];

const TablePaginationActions: React.FC<TablePaginationActionsProps> = (props) => {
  const { count, page, rowsPerPage, onChangePage } = props;
  const classes = useStyles();

  const maxPage = Math.ceil(count / rowsPerPage) - 1;
  return (
    <div className={classes.root}>
      <IconButton onClick={_ => onChangePage(null, 0)} disabled={page === 0} aria-label="první strana" >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={_ => onChangePage(null, page - 1)} disabled={page === 0} aria-label="předchozí strana">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={_ => onChangePage(null, page + 1)} disabled={page >= maxPage} aria-label="další strana">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={_ => onChangePage(null, Math.max(0, maxPage))} disabled={page >= maxPage} aria-label="poslední strana">
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

const MuiPagination: React.FC<MuiPaginationProps> = props => {
  const { count, pageSizesOptions, page, pageSize, onChangePage, onChangeRowsPerPage } = props;

  return (
    <TablePagination
      ActionsComponent={TablePaginationActions}
      rowsPerPageOptions={pageSizesOptions}
      component="div"
      count={count}
      rowsPerPage={pageSize}
      page={page}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count} datasetů`}
      labelRowsPerPage="Záznamů na stránku:"
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
}

export default MuiPagination;
