import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/styles";

import { MuiThemeProvider } from "@material-ui/core/styles";

import './globalStyle.css';
import { theme } from "./theme";

import { Dashboard } from "./modules/search/dashboard";
import { Detail } from "./modules/search/detail";
import { Header } from './components/header';
import { Footer } from './components/footer';

const useStyles = makeStyles({
  base: {
    minHeight: "100vh",
    maxWidth: "1024px",
    margin: "0 auto",
    padding: "0 5px",
    ["@media (min-width:700px)"]: {
      padding: "0 30px"
    }
  }
});
export const App = () => {
  const classes = useStyles();

  const filterVersion = "3";
  const current = window.localStorage.getItem('version');
  if (current !== filterVersion) {
    window.localStorage.removeItem('filters');
    window.localStorage.setItem('version', filterVersion);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <div className={classes.base}>
        <main>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/:id" component={Detail} />
            </Switch>
          </BrowserRouter>
        </main>
      </div>
      <Footer />
    </MuiThemeProvider>
  )
};
