import _ from 'lodash';

export const empty = { code: '', tag: '', label: '', value: '', checked: false };

export const location = {
  PRAHA: { code: '0', tag: 'location', label: 'Praha', value: 'PRAHA', checked: false },
  CESKE_BUDEJOVICE: { code: '1', tag: 'location', label: 'České Budějovice', value: 'CESKE_BUDEJOVICE', checked: false },
  PLZEN: { code: '2', tag: 'location', label: 'Plzeň', value: 'PLZEN', checked: false },
  USTI_NAD_LABEM: { code: '3', tag: 'location', label: 'Ústí nad Labem', value: 'USTI_NAD_LABEM', checked: false },
  HRADEC_KRALOVE: { code: '4', tag: 'location', label: 'Hradec Králové', value: 'HRADEC_KRALOVE', checked: false },
  BRNO: { code: '5', tag: 'location', label: 'Brno', value: 'BRNO', checked: false },
  OSTRAVA: { code: '6', tag: 'location', label: 'Ostrava', value: 'OSTRAVA', checked: false },
  // LIBEREC: { code: '7', tag: 'location', label: 'Liberec', value: 'LIBEREC', checked: false },
  // PARDUBICE: { code: '8', tag: 'location', label: 'Pardubice', value: 'PARDUBICE', checked: false },
  // OLOMOUC: { code: '9', tag: 'location', label: 'Olomouc', value: 'OLOMOUC', checked: false },
};

export const legalForm = {
  AS: { code: '10', tag: 'legalForm', label: 'Akciová společnost', value: 'AS', checked: false},
  DR: { code: '11', tag: 'legalForm', label: 'Družstvo', value: 'DR', checked: false},
  EVRDRSPOL: { code: '12', tag: 'legalForm', label: 'Evropská družstevní společnost', value: 'EVRDRSPOL', checked: false},
  EVRSPOL: { code: '13', tag: 'legalForm', label: 'Evropská společnost', value: 'EVRSPOL', checked: false},
  EVRHZS: { code: '14', tag: 'legalForm', label: 'Evropské hospodářské zájmové sdružení', value: 'EVRHZS', checked: false},
  KS: { code: '15', tag: 'legalForm', label: 'Komanditní společnost', value: 'KS', checked: false},
  MODBORORG: { code: '16', tag: 'legalForm', label: 'Mezinárodní odborová organizace', value: 'MODBORORG', checked: false},
  MORGZAM: { code: '17', tag: 'legalForm', label: 'Mezinárodní organizace zaměstnavatelů', value: 'MORGZAM', checked: false},
  NAD: { code: '18', tag: 'legalForm', label: 'Nadace', value: 'NAD', checked: false},
  NADF: { code: '19', tag: 'legalForm', label: 'Nadační fond', value: 'NADF', checked: false},
  ODBORORG: { code: '20', tag: 'legalForm', label: 'Odborová organizace', value: 'ODBORORG', checked: false},
  OSZPO: { code: '21', tag: 'legalForm', label: 'Odštěpný závod zahraniční právnické osoby', value: 'OSZPO', checked: false},
  ORGZAM: { code: '22', tag: 'legalForm', label: 'Organizace zaměstnavatelů', value: 'ORGZAM', checked: false},
  OSZNAD: { code: '23', tag: 'legalForm', label: 'Organizační složka zahraniční nadace', value: 'OSZNAD', checked: false},
  OSZNADF: { code: '24', tag: 'legalForm', label: 'Organizační složka zahraničního nadačního fondu', value: 'OSZNADF', checked: false},
  POBSPOLEK: { code: '25', tag: 'legalForm', label: 'Pobočný spolek', value: 'POBSPOLEK', checked: false},
  PRISP: { code: '26', tag: 'legalForm', label: 'Příspěvková organizace', value: 'PRISP', checked: false},
  SPOLEK: { code: '27', tag: 'legalForm', label: 'Spolek', value: 'SPOLEK', checked: false},
  SVJ: { code: '28', tag: 'legalForm', label: 'Společenství vlastníků jednotek', value: 'SVJ', checked: false},
  SRO: { code: '29', tag: 'legalForm', label: 'Společnost s ručením omezeným', value: 'SRO', checked: false},
  VOS: { code: '30', tag: 'legalForm', label: 'Veřejná obchodní společnost', value: 'VOS', checked: false},
  ZAHRFOS: { code: '31', tag: 'legalForm', label: 'Zahraniční fyzická osoba', value: 'ZAHRFOS', checked: false},
  ZAJZDRPO: { code: '32', tag: 'legalForm', label: 'Zájmové sdružení právnických osob', value: 'ZAJZDRPO', checked: false},
  USTAV: { code: '33', tag: 'legalForm', label: 'Ústav', value: 'USTAV', checked: false},
  PFOT: { code: '34', tag: 'legalForm', label: 'Podnikající fyzická osoba tuzemská', value: 'PFOT', checked: false},
  SPOL_POD: { code: '35', tag: 'legalForm', label: 'Společný podnik', value: 'SPOL_POD', checked: false},
  ZAJ_SDR: { code: '36', tag: 'legalForm', label: 'Zájmové sdružení', value: 'ZAJ_SDR', checked: false},
  OPS: { code: '37', tag: 'legalForm', label: 'Obecně prospěšná společnost', value: 'OPS', checked: false},
  KOM_BUR: { code: '38', tag: 'legalForm', label: 'Komoditní burza', value: 'KOM_BUR', checked: false},
  GF_OCP: { code: '39', tag: 'legalForm', label: 'Garanční fond obchodníků s cennými papíry', value: 'GF_OCP', checked: false},
  SP: { code: '40', tag: 'legalForm', label: 'Státní podnik', value: 'SP', checked: false},
  NP: { code: '41', tag: 'legalForm', label: 'Národní podnik', value: 'NP', checked: false},
  SZDC: { code: '42', tag: 'legalForm', label: 'Státní organizace Správa železnic', value: 'SZDC', checked: false},
  CTK: { code: '43', tag: 'legalForm', label: 'Česká tisková kancelář', value: 'CTK', checked: false},
  SF_ZZ: { code: '44', tag: 'legalForm', label: 'Státní fond ze zákona', value: 'SF_ZZ', checked: false},
  ZP: { code: '45', tag: 'legalForm', label: 'Zdravotní pojišťovna (mimo VZP)', value: 'ZP', checked: false},
  ZAHR_OS: { code: '46', tag: 'legalForm', label: 'Zahraniční osoba', value: 'ZAHR_OS', checked: false},
  OS_ZFO: { code: '47', tag: 'legalForm', label: 'Odštěpný závod zahraniční fyzické osoby', value: 'OS_ZFO', checked: false},
  OZ: { code: '48', tag: 'legalForm', label: 'Odštěpný závod', value: 'OZ', checked: false},
  SAM_DR_PROV: { code: '49', tag: 'legalForm', label: 'Samostatná drobná provozovna (obecního úřadu)', value: 'SAM_DR_PROV', checked: false},
  OS: { code: '50', tag: 'legalForm', label: 'Občanské sdružení', value: 'OS', checked: false},
  ODBOR_ORG: { code: '51', tag: 'legalForm', label: 'Odborová organizace a organizace zaměstnavatelů', value: 'ODBOR_ORG', checked: false},
  ZVLASTNI_ORG: { code: '52', tag: 'legalForm', label: 'Zvláštní organizace pro zastoupení českých zájmů v mezinárodních nevládních organizacích', value: 'ZVLASTNI_ORG', checked: false},
  PODN_HZ_SDR: { code: '53', tag: 'legalForm', label: 'Podnik nebo hospodářské zařízení sdružení', value: 'PODN_HZ_SDR', checked: false},
  PODN_HZ_PS: { code: '54', tag: 'legalForm', label: 'Podnik nebo hospodářské zařízení politické strany', value: 'PODN_HZ_PS', checked: false},
  P_ODBOR_ORG: { code: '55', tag: 'legalForm', label: 'Pobočná odborová organizace a organizace zaměstnavatelů', value: 'P_ODBOR_ORG', checked: false},
  KOMORA_HA: { code: '56', tag: 'legalForm', label: 'Komora (hospodářská, agrární)', value: 'KOMORA_HA', checked: false},
  ZAJ_SDR_PO: { code: '57', tag: 'legalForm', label: 'Zájmové sdružení právnických osob zapsané v OR', value: 'ZAJ_SDR_PO', checked: false},
  OBEC: { code: '58', tag: 'legalForm', label: 'Obec', value: 'OBEC', checked: false},
  NEVLAD_ORG: { code: '59', tag: 'legalForm', label: 'Mezinárodní nevládní organizace', value: 'NEVLAD_ORG', checked: false},
  P_NEVLAD_ORG: { code: '60', tag: 'legalForm', label: 'Organizační jednotka mezinárodní nevládní organizace', value: 'P_NEVLAD_ORG', checked: false},
  Z_POBOCNY_SPOLEK: { code: '61', tag: 'legalForm', label: 'Zahraniční pobočný spolek', value: 'Z_POBOCNY_SPOLEK', checked: false},
  PO_ZZZ: { code: '62', tag: 'legalForm', label: 'Právnická osoba zřízená zvláštním zákonem zapisovaná do veřejného rejstříku', value: 'PO_ZZZ', checked: false},
  SF: { code: '63', tag: 'legalForm', label: 'Svěřenský fond', value: 'SF', checked: false},
  ZSF: { code: '64', tag: 'legalForm', label: 'Zahraniční svěřenský fond', value: 'ZSF', checked: false},
};

// export const year = _
//   .range(1900, new Date().getFullYear() + 1)
//   .map(year => year.toString())
//   .map(str => ({ code: str, tag: 'year', label: str, value: str, checked: false }))
//   .reduce((obj: any, item) => {
//     obj[item.code] = item;
//     return obj;
//   }, {});
// resulting structure:
// {
//   '2019': { code: '2019', tag: 'year', label: '2019', value: '2019', checked: false },
//   '2018': { code: '2018', tag: 'year', label: '2018', value: '2018', checked: false },
// };

//special care since year got textfield instead of checkbox
// code = text field placeholder
// label = value from textfield (and for keywords)
// value = filters for fetch
export const year = {
  TEXT: { code: '2001, 2003, 2014-2018', tag: 'year', label: '', value: '', checked: false, textfield: true }
};

export const format = {
  CSV_GZ: { code: '1', tag: 'format', label: 'CSV.GZ', value: 'CSV_GZ', checked: false },
  XML_GZ: { code: '2', tag: 'format', label: 'XML.GZ', value: 'XML_GZ', checked: false },
  CSV: { code: '4', tag: 'format', label: 'CSV', value: 'CSV', checked: false },
  XML: { code: '8', tag: 'format', label: 'XML', value: 'XML', checked: false },
};

export const type = {
  FULL: { code: '0', tag: 'type', label: 'Úplný', value: 'FULL', checked: false },
  ACTUAL: { code: '1', tag: 'type', label: 'Platný', value: 'ACTUAL', checked: false }
};
