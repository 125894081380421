import React from 'react';
import _ from 'lodash';
import { Link, Typography } from '@material-ui/core';

import { ReactComponentElement } from 'react';

import { location, legalForm, year, format, type, empty } from './_filters';
import { FilterValue } from '../../components/filter-box';
import { defaultPageSizesOptions } from '../../components/pagination';
import { Filter } from './dashboard';

/**
 *
 * @param values all checkbox values from filters
 *
 * Return only filters with checked=true
 */
export function getAppliedFilters(values: Filter): FilterValue[] {
  const reducedFilters = _(values).reduce((o, values) => ({ ...o, ...values }), {});

  const appliedFilters: FilterValue[] = _(reducedFilters)
    .values()
    .filter('checked')
    .value() as FilterValue[];

  const appliedFiltersText: FilterValue[] = _(reducedFilters)
    .values()
    .filter((v: FilterValue): boolean => _.isEqual(v.textfield, true) && !_.isEmpty(v.label))
    .value() as FilterValue[];

  return [...appliedFilters, ...appliedFiltersText];
}

// TODO: consider usage of memoize() ? ... for all parsers...
function legalFormParser(packet: string): any {
  const code = packet.substring(0, 2);
  const value: FilterValue =
    _(legalForm)
      .values()
      .find(['code', code]) || empty;

  return value;
}

function typeParser(packet: string): any {
  const code = packet.substring(2, 3);
  const value: FilterValue =
    _(type)
      .values()
      .find(['code', code]) || empty;

  return value;
}

function locationParser(packet: string): any {
  const code = packet.substring(3, 4);
  const value: FilterValue =
    _(location)
      .values()
      .find(['code', code]) || empty;

  return value;
}

export function yearParser(packet: string): string {
  return packet.substring(4, 8);
  // const value: FilterValue =
  //   _(year)
  //     .values()
  //     .find(['code', code]) || empty;

  // return value.label;
}

/**
 *
 * @param packet stringified decimal number
 */
export function formatParser(packet: string): JSX.Element[] {
  const code = packet.substring(8, 10); // cut last 2 chars
  const value = parseInt(code); // convert to int
  // convert to array of values acording to filter codes
  const values: any = _(format)
    .values()
    .filter((f: FilterValue): boolean => (parseInt(f.code) & value) !== 0)
    .value();

  // const stringValue = _.join(values.map((v: any) => v.label), ' | ');

  // compose clickable label to table cell
  return _.map(values, (v, i) => (
    <React.Fragment key={v.label}>
      <Typography variant="button">
        <Link underline='always' href={`/api/file/${idParser(packet)}.${v.label.toLowerCase()}`}>{v.label}</Link>
      </Typography>
      {values.length !== i + 1 && <Typography variant="button">&nbsp;|&nbsp;</Typography>}
    </React.Fragment>
  ));
}

export function nameParser(packet: string): string {
  const legalForm = legalFormParser(packet);
  const type = typeParser(packet);
  const location = locationParser(packet);
  const year = yearParser(packet);

  return `${legalForm.label} - ${type.label} výpis (${location.label} - ${year})`;
}

export function idParser(packet: string): string {
  const legalForm = legalFormParser(packet);
  const type = typeParser(packet);
  const location = locationParser(packet);
  const year = yearParser(packet);

  return `${legalForm.value.toLowerCase()}-${type.value.toLowerCase()}-${location.value.toLowerCase()}-${year}`;
}

//not used
export function descriptionParser(packet: string): string {
  return 'popis';
}

//not used
export function getRandomRows(): object[] {
  const rows: any = [];
  for (let i = 0; i < 50000; i += 1) {
    rows.push({
      id: '1005201815',
      name: '1005201815',
      description: '1005201815',
      formats: '1005201815'
    });
  }

  return rows;
}

/**
 * Create initial values for filters
 */
export function getInitialFilters(): Filter {
  const cachedFilters = window.localStorage.getItem('filters');

  if (cachedFilters) {
    return JSON.parse(cachedFilters);
  }
  return {
    location,
    legalForm,
    year,
    format,
    type
  };
}

/**
 * Get unchecked filters
 */
export function getEmptyFilters(): any {
  const init = {
    location,
    legalForm,
    year,
    format,
    type
  };
  const unchecked = _.mapValues(init, (filters) => {
    return _.mapValues(filters, (value: FilterValue, key, filter) => {
      return {
        ...value,
        checked: false
      }
    })
  });
  return unchecked;
}

/**
 * Create initial values for sort
 */
export function getInitialSort(): any {
  const cachedSort = window.localStorage.getItem('sort');

  if (cachedSort) {
    return JSON.parse(cachedSort);
  }

  return { sortBy: 'name', sortDirection: 'ASC' };
}

export function getInitialPageSize(): any {
  const cachedPageSize = window.localStorage.getItem('pagesize');

  if (cachedPageSize) {
    return +cachedPageSize;
  }

  return defaultPageSizesOptions[0];
}
