import React, { useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { TextField as MuiTextField } from '@material-ui/core';

import { fieldToTextField, TextFieldProps } from 'formik-material-ui';
import { ListItem } from '@material-ui/core';
import { FilterValue } from './filter-box';

const useStyles = makeStyles({
  root: {
    padding: '0px !important' // couldnt find a way how to override some css
  },
  dense: {
    padding: '3px !important'
  },
  label: {
    cursor: 'pointer'
  }
});

interface Outter {
  filter: FilterValue;
}

//TODO probably shouldn't be here
const sanitize = (text: string) => text.replace(/\s/g,'').replace(/[^\d,-]/g,'');
//TODO shouldn't be here
const validateInput = (sanitizedText: string): boolean => /^((\d+|\d+-\d+),)*(\d+|\d+-\d+){0,1}$/.test(sanitizedText);
const validateValues = (values: number[]): string => {
  const min = 1900;
  const max = new Date().getFullYear();
  const wrongValues = values.filter(v => v < min || v > max)
  return wrongValues.length > 0 ? `Minimální rok ${min}, maximální rok ${max}` : '';
}

//TODO definitely shoudln't be here
export const computeYearValues = (sanitizedInput: string): number[] => {
  const split = sanitizedInput.split(',').filter(str => !_.isEmpty(str));
  const years = _.flatMap(split, yearStr => {
    if (yearStr.includes('-')) {
      const yearSplit = yearStr.split('-');
      const min = _.min([parseInt(yearSplit[0]), parseInt(yearSplit[1])]) || 0
      const max = _.max([parseInt(yearSplit[0]), parseInt(yearSplit[1])]) || 0
      return _.range(min, max + 1)
    } else {
      return [parseInt(yearStr)]
    }
  })
  return _.uniq(years)
}

export const TextField: React.FC<TextFieldProps & Outter> = props => {
  const {
    form: { setFieldValue, values },
    field: { name, value },
    filter
  } = props;

  const classes = useStyles();
  const [errorText, setErrorText] = useState('');

  const textFieldValue = (value['TEXT'] || value).label;
  const lastValue = (value['TEXT'] || value).value || "";
  const sanitizedValue = sanitize(textFieldValue);
  return (
    <ListItem
        key={name}
        role={undefined}
        className={classes.dense}
        dense
        button
      >
        <MuiTextField 
          {...fieldToTextField(props)}
          classes={{
            root: classes.root
          }}
          variant="standard"
          InputProps={{
            disableUnderline: true
          }}
          value={sanitizedValue}
          onChange={async event => {
            const sanitizedInput = sanitize(event.target.value);
            await setFieldValue(name, { ...filter, label: sanitizedInput, value: lastValue });

            if (validateInput(sanitizedInput)) {
              const computedValues = computeYearValues(sanitizedInput);
              const validationResult = validateValues(computedValues);
              if (_.isEmpty(validationResult)) {                  
                const computedValuesStr = computeYearValues(sanitizedInput).join(','); //inOperationSeparator !

                await setFieldValue(name, { ...filter, label: sanitizedInput, value: computedValuesStr });
                // TODO: separate into util
                const updatedValues = _.update(values, name, o => ({ 
                  ...o, 
                  label: sanitizedInput, 
                  value: computedValuesStr
                })); 

                window.localStorage.setItem('filters', JSON.stringify(updatedValues));
                setErrorText('');
              } else {
                setErrorText(validationResult);
              }
            } else {
              setErrorText("Špatný formát");
            }
          }}
          placeholder={filter.code}
          multiline
          helperText={errorText}
          error={!_.isEmpty(errorText)}
        />
      </ListItem>
  )
};
