export const frequency: any = {
  ANNUAL: 'roční',
  ANNUAL_2: 'pololetní',
  ANNUAL_3: 'třikrát do roka',
  BIENNIAL: 'dvouletý',
  BIMONTHLY: 'dvouměsíční',
  BIWEEKLY: 'čtrnáctidenní',
  CONT: 'průběžný',
  DAILY: 'denní',
  DAILY_2: 'dvakrát denně',
  DECENNIAL: 'decennial [en]',
  HOURLY: 'hourly [en]',
  IRREG: 'nepravidelný',
  MONTHLY: 'měsíční',
  MONTHLY_2: 'dvakrát za měsíc',
  MONTHLY_3: 'třikrát za měsíc',
  NEVER: 'nikdy',
  OTHER: 'ostatní',
  QUADRENNIAL: 'quadrennial [en]',
  QUARTERLY: 'čtvrtletní',
  QUINQUENNIAL: 'quinquennial [en]',
  TRIENNIAL: 'tříletý',
  UNKNOWN: 'neznámý',
  UPDATE_CONT: 'průběžně aktualizovaný',
  WEEKLY: 'týdenní',
  WEEKLY_2: 'dvakrát týdně',
  WEEKLY_3: 'třikrát týdně',
}