import React from "react";

import "./detailMeta.css";

interface DetailMetaProps {
  heading: string;
  text: string | React.ReactNode;
  optionalComponent?: React.ReactNode;
}

export const DetailMeta: React.FC<DetailMetaProps> = ({
  heading,
  text,
  optionalComponent
}) => {
  return (
    <>
      <dt className="detail-meta__dt">{heading}</dt>
      <dd className="detail-meta__dd">
        {text}
        {optionalComponent}
      </dd>
    </>
  );
};
