import 'whatwg-fetch'

import _ from 'lodash';
import { Dataset } from './detail'
import { FilterValue } from './../../components/filter-box';

const serialize = (obj: any) => Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&')

const fetchData = (url: string, { params = {}, ...options }) => {
  const queryString = serialize(params)
  const questionMark = queryString ? '?' : ''
  return fetch(`${url}${questionMark}${queryString}`, options)
};

const FilterOperation = {
  EQ: '=',
  LT: '<',
  GT: '>',
  IN: ':',
}
const filterSeparator = ';'
const inOperationSeparator = ','

export interface Data {
  count: number,
  rows: object[],
}

export async function getRowsFromAPI(page: number, pageSize: number, params?: FilterValue[]): Promise<Data> {
  const qStr = _(params)
    .groupBy(p => p.tag)
    .map((value, key) => `${key}${FilterOperation.IN}${value.map(v => v.value).join(inOperationSeparator)}`)
    .join(filterSeparator)

  const response = await fetchData("/api/3/action/package_list_compact", {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    params: qStr ? { q: qStr, page, pageSize } : { page, pageSize },
  })
  const result: any = await response.json()

  const formatedRows = result.compactedDatasets
    .map((i: number) => {
      const s = i.toString();
      return { id: s, name: s, formats: s }
    });

  return { count: result.count, rows: formatedRows }
}

export async function getDatasetFromAPI(id: string): Promise<Dataset> {
  const response = await fetchData("/api/3/action/package_show", {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    params: { id },
  })
  const dataset: Dataset = await response.json()

  return dataset;
}
