import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import classNames from "classnames";

const useStyles = makeStyles({
  copyright: {
    marginBottom: "20px !important"
  },
  footerTextColor: {
    color: "white !important"
  },
  footerLink: {
    whiteSpace: "nowrap",
  },
  footer: {
    marginTop: "15px",
    padding: "30px 0",
    background: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADyCAIAAAADcThpAAAAg0lEQVQoz+WSyxGAIAxEd6jHTqzNmp8HFJINOuPZA8OGT3hko+3Ym6SfDPpMj4l7kM4yztm6r3Hn8dw57nc9V84jEd694qDnW5OXoidLYiUzYX9mpQuT8UHiKfqBZehYq+gJT8wvXGQv13V64/Ea88k75F7mf1U/F3yLXsT6o/anmqQTA1GUVTSyykAAAAAASUVORK5CYII=') repeat-x scroll 0 0 #01551c"
  }
});

const links = [
  { href: "/files/ISVR_OpenData_FAQ.pdf", label: "Často kladené dotazy" },
  { href: "/files/ISVR_OpenData_Podminky_uziti.pdf", label: "Podmínky užití" },
  { href: "/files/ISVR_OpenData_Uzivatelska_prirucka.pdf", label: "Uživatelská příručka" },
  { href: "https://or.justice.cz", label: "Veřejný rejstřík a Sbírka listin" },
  { href: "https://www.justice.cz/", label: "justice.cz" },
  { href: "mailto:dataor@msp.justice.cz", label: "Kontaktní bod: dataor@msp.justice.cz" },
]
export const Footer = () => {
  const classes = useStyles();

  return (
    <footer>
      <div className={classes.footer}>
        <Typography variant="body2" align="center" gutterBottom className={classNames(classes.copyright, classes.footerTextColor)}>
          {
            _.map(links, (v, i) => (
              <span key={i}>
                <Link underline="always" href={v.href} className={classNames(classes.footerTextColor, classes.footerLink)}>{v.label}</Link>
                {links.length !== i + 1 && <span> | </span>}
              </span>
            ))
          }
        </Typography>

        <Typography variant="body2" align="center" className={classes.footerTextColor}>
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAAWCAYAAABEx1soAAACfElEQVRYw+1YUbWAIAw1ghGIQAQjGMEIRLABEYhgBCIYwQhE2PsZ5+3tbYCKf+wcP0Q2x2XcjU0TEQBwALALj5kUAQADACsAzNMQXQDAgiypojfneQBgB5I6UIsCcGzQPXHuMZC8D/DZoJvy5IFkI1BMbEFnpRNf/n8jvL98TIczeY/ksV8CvGlRLCUx5N+rI8CRmNo/WN9O7C9kHKTxr0BelUi+cAMMPk6aNwBuc2RGZzTKUKXB7vI1wJhPzA2AiyWpZk+gHfM50IWfRzY1cNqRAFbGdl7loK9eOHVWiNI/EStFMNoLgr1V2LRUmtMKtH8CMNJJaikBXwKs+ZdIrX4H4KOgYwU/4DXVIFjxJsA0Cg5c7CFVKC8BpjKzhL2izsVO0I5r4oDzS5djPgdh0yzTi72SRQ3gS4iOVeLajgA7AvKs2FeTnGKfRnpka4jCpQvo8Z8fgLw1AlwT3wngS6CGQBPPQ4B3mqBzwqvQw+/pRIXzIciuA8CxE8BW4fpEOLMV4KNWzTQCvNDQT7ez33+nawDTDG1ayrRWgIldR49p5swXERzIBnr8thV8sLUeRLxTz3GqKDSCIG8g46+c+Hi1UQLYc39xLJJNdEKUU1tbAWCqewk+cw4+lfWaUpMntJQaXL9ylE58p05oR80JVUgSqpjIsveJiw+c55luEnSBcCxvFSQhidIxzzb+0jK91IfYNI5mzpxKDX2WNlGrXQvJNFWik881Ws4o1MG+1pcp9G7+1sHsiGnPIXWamIO+ciOk9hy75eUn8P8w3bwp+d0r8yKvIpQ5VuumIYD0mxdunzkpFnF6UxM7HnVD+jaGLnrnH9K/telG5OryA5C3jhUaaahzAAAAAElFTkSuQmCC" />
        </Typography>
        <Typography variant="body2" align="center" className={classes.footerTextColor}>
          © 2019 Ministerstvo spravedlnosti České republiky
        </Typography>
        <Typography variant="body2" align="center" className={classes.footerTextColor}>
          Verze aplikace: 1.1
        </Typography>
      </div>
    </footer>
  );
};
