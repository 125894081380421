import { nameParser, formatParser } from './_utils';

export const columns = [
  {
    width: .8,
    flexGrow: 1.0,
    label: 'Název',
    dataKey: 'name',
    parser: nameParser,
    disableSort: true
  },
  {
    width: 250,
    label: 'Formáty',
    dataKey: 'formats',
    parser: formatParser,
    disableSort: true,
    noClick: true
  }
];
