import React, { useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import SvgIcon  from '@material-ui/core/SvgIcon';
import { FastField, FieldArray } from "formik";

import { Checkbox } from "./checkbox";
import { TextField } from "./textfield";

interface FilterListProps {
  name: string;
  label: string;
  array: FilterValues;
}

export interface FilterValue {
  code: string;
  tag: string;
  label: string;
  value: string;
  checked: boolean;
  textfield?: boolean;
}

export interface FilterValues {
  [key: string]: FilterValue;
}

const useStyles = makeStyles({
  card: {
    marginBottom: 10
  },
  cardContent: {
    padding: "0 !important"
  },
  root: {
    width: "100%",
    backgroundColor: "#efefef",
    position: "relative",
    overflow: "auto",
    maxHeight: 210
  },
  dense: {
    padding: "3px !important"
  },
  title: {
    cursor: "pointer",
    color: "black !important",
    fontWeight: "bold !important" as "bold",
    lineHeight: "24px !important",
    backgroundColor: "#efefef"
  },
  icon: {
    marginBottom: "-6px !important"
  },
  hide: {
    display: "none"
  }
});

interface HeaderProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  label: string,
}

const Header: React.FC<HeaderProps> = ({ open, setOpen, label }) => {
  const classes = useStyles();
  return (
    <CardHeader
      onClick={e => setOpen(prev => !prev)}
      className={classNames(classes.dense, classes.title)}
      aria-expanded={open}
      title={
        <>
          <SvgIcon className={classes.icon}>
            {open && <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>}
            {!open && <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>}
          </SvgIcon>
          {label}
        </>
      }
      titleTypographyProps={{variant: "subtitle2", className: classes.title}}
    />
  )
}

export const FilterList: React.FC<FilterListProps> = ({
  name,
  label,
  array,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  return (
    <Card className={classes.card}>
      <Header open={open} setOpen={setOpen} label={label}/>
      <CardContent className={classes.cardContent}>
        <FieldArray name={name}>
          {({ form: { values } }) => {
            // added to keep order of filters with numeric keys
            const keys = Object.keys(array);
            return (
              <div className={classNames(classes.root, { [classes.hide]: !open })}>
                {keys.map(key => {
                  const filter = array[key];

                  return (
                    <FastField
                      key={filter.value}
                      name={`${name}.${filter.value}`}
                      filter={filter}
                      component={Checkbox}
                    />
                  );
                })}
              </div>
            );
          }}
        </FieldArray>
      </CardContent>
    </Card>
  );
};

export const FilterText: React.FC<FilterListProps> = ({
  name,
  label,
  array, 
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const defaultTextFilter = array['TEXT']
  return (
    <Card className={classes.card}>
      <Header open={open} setOpen={setOpen} label={label}/>
      <CardContent className={classes.cardContent}>
        <div className={classNames(classes.root, { [classes.hide]: !open })}>
          <FastField
            name={`${name}.TEXT`}
            filter={defaultTextFilter} 
            component={TextField}
          />
        </div>
      </CardContent>
    </Card>
  );
};
