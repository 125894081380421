import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Link from "@material-ui/core/Link";
import * as H from "history";
import {
  AutoSizer,
  Column,
  SortDirection,
  Table,
  Index,
  RowMouseEventHandlerParams,
  SortDirectionType,
  TableCellProps,
  TableHeaderProps
} from "react-virtualized";

const styles = () => ({
  table: {
    fontFamily: "arial"
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box" as "border-box"
  },
  tableRow: {
    cursor: "pointer"
    // textDecoration: 'underline'
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: "#efefef"
    }
  },
  tableCell: {
    flex: 1
  },
  noClick: {
    cursor: "initial"
  }
});

interface MuiVirtualizedTableProps {
  classes: {
    table: string;
    flexContainer: string;
    tableRow: string;
    tableRowHover: string;
    tableCell: string;
    noClick: string;
  };
  formatter: (id: string) => void;
  rowClassName: string;
  history: H.History<any>;
  columns: {
    // TODO convert to inteface
    className?: boolean;
    disableSort?: boolean;
    numeric?: boolean;
    noClick?: boolean;
    width: number;
    flexGrow?: number;
    label: string;
    dataKey: string;
    parser: (x: string) => string | JSX.Element[];
  }[];
  rowHeight: number;
  headerHeight: number;
  sort:
    | ((info: { sortBy: string; sortDirection: SortDirectionType }) => void)
    | undefined;
  sortBy: string;
  sortDirection: "ASC" | "DESC" | undefined;
  rowCount: number;
  rowGetter: ((info: Index) => void) | undefined;
}

class MuiVirtualizedTable extends React.PureComponent<
  MuiVirtualizedTableProps
> {
  getRowClassName = ({ index }: Index) => {
    const { classes, rowClassName } = this.props;

    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1
    });
  };

  cellRenderer = ({ cellData, columnIndex }: TableCellProps) => {
    const { columns, classes, rowHeight, history, formatter } = this.props;

    const noClickable = !!columns[columnIndex].noClick;
    const onCellClick = () => {
      if (!noClickable) {
        history.push(`/${formatter(cellData.raw.id)}`);
      }
    };

    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: noClickable
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
        onClick={onCellClick}
      >
        {columnIndex === 0 ? (
          <Link tabIndex={0} underline="always">{cellData.parsed}</Link>
        ) : (
          cellData.parsed
        )}
      </TableCell>
    );
  };

  headerRenderer = ({
    label,
    columnIndex,
    dataKey,
    sortBy,
    sortDirection
  }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, columns, classes, sort } = this.props;
    const direction = {
      [SortDirection.ASC]: "asc" as "asc",
      [SortDirection.DESC]: "desc" as "desc"
    };

    const inner =
      !columns[columnIndex].disableSort && sort != null ? (
        <TableSortLabel
          active={dataKey === sortBy}
          direction={sortDirection ? direction[sortDirection] : undefined}
        >
          {label}
        </TableSortLabel>
      ) : (
        label
      );

    return (
      <TableCell
        component="div"
        className={classNames(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, ...tableProps } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            className={classes.table}
            height={height}
            width={width}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ className, dataKey, parser, ...other }, index) => {
              const renderer = this.cellRenderer;

              return (
                <Column
                  key={dataKey}
                  headerRenderer={headerProps =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index
                    })
                  }
                  className={classNames(classes.flexContainer, className)}
                  cellRenderer={renderer}
                  cellDataGetter={props => ({
                    raw: props.rowData,
                    parsed: parser(props.rowData[props.dataKey])
                  })}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

export const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);
