import { createMuiTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['"Verdana"', '"sans-serif"'].join(",")
  },
  palette: {
    secondary: {
      main: green[900]
    }
  },
  overrides: {
    MuiChip: {
      label: {
        whiteSpace: "normal"
      }
    },
    MuiTypography: {
      subtitle1: {
        fontWeight: "bold"
      }
    },
    MuiTablePagination: {
      select: {
        paddingRight: '24px'
      }
    },
  }
});
