import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Checkbox as MuiCheckbox } from '@material-ui/core';

import { fieldToCheckbox, CheckboxProps } from 'formik-material-ui';
import { ListItem, ListItemText } from '@material-ui/core';
import { FilterValues } from './filter-box';

const useStyles = makeStyles({
  root: {
    padding: '0px !important' // couldnt find a way how to override some css
  },
  dense: {
    padding: '3px !important'
  },
  label: {
    cursor: 'pointer'
  }
});

interface Outter {
  filter: FilterValues;
}

export const Checkbox: React.FC<CheckboxProps & Outter> = React.memo(props => {
  const {
    form: { setFieldValue, values },
    field: { name, value },
    filter
  } = props;

  const classes = useStyles();
  // console.log(name);

  // filters cached -> new filter added -> value is undefined in onClick/onChange!
  const valToSet = value ? value : filter;

  const child = React.useMemo(
    () => (
      <ListItem
        key={name}
        role={undefined}
        className={classes.dense}
        dense
        button
        onClick={async () => {
          // const start = Date.now();
          await setFieldValue(name, { ...valToSet, checked: !valToSet.checked });
          // console.log(`setFieldValue ran ${Date.now() - start}`);

          // TODO: separate into util
          const updatedValues = _.update(values, name, o => ({ ...o, checked: !o.checked }));

          window.localStorage.setItem('filters', JSON.stringify(updatedValues));
        }}
      >
        <MuiCheckbox
          {...fieldToCheckbox(props)}
          classes={{
            root: classes.root
          }}
          checked={valToSet.checked}
          onChange={async () => {
            // const start = Date.now();
            await setFieldValue(name, { ...valToSet, checked: !valToSet.checked });
            // console.log(`setFieldValue ran ${Date.now() - start}`);

            // TODO: separate into util
            const updatedValues = _.update(values, name, o => ({ ...o, checked: !o.checked }));

            window.localStorage.setItem('filters', JSON.stringify(updatedValues));
          }}
        />
        <ListItemText primary={<label className={classes.label}>{valToSet.label}</label>} />
      </ListItem>
    ),
    [valToSet.checked]
  );
  return <>{child}</>;
});
