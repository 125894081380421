import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Typography,
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from "@material-ui/core";
import { RouteChildrenProps } from "react-router";
import { getDatasetFromAPI } from "./_fetch";
import { location, empty, format } from "./_filters";
import { frequency } from "./_frequency";
import { DetailMeta } from "./detailMeta";

const useStyles = makeStyles({
  root: {
    padding: "0 30px"
  },
  title: {
    marginTop: "20px !important"
  },
  notes: {
    marginTop: "20px !important",
    marginBottom: "20px !important"
  },
  table: {
    overflow: "auto",
    marginBottom: "10px"
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  copyright1: {
    marginTop: "15px !important"
  },
  notesCols: {
    listStylePosition: "inside",
  },
  notesHeader: {
    marginTop: "5px",
  }
});

export interface Dataset {
  success: boolean;
  result: {
    id: string;
    year: number;
    location: string;
    legalForm: string;
    type: string;
    title: string;
    notes: string;
    frequency: string;
    ruian_type: string;
    ruian_code: string;
    tags: {
      name: string;
    }[];
    publisher_uri: string;
    publisher_name: string;
    resources: {
      id: string;
      url: string;
      format: string;
      license_link: string;
      formatEnum: string;
      describedBy: string | null;
      describedByUrl: string | null;
      apiLicence: string;
      apiUrl: string;
    }[];
  };
}

export const Detail: React.FC<RouteChildrenProps> = ({ history, match }) => {
  const classes = useStyles();
  const id = (match!.params as any).id;

  const [dataset, setDataset] = React.useState<Dataset>({} as Dataset);

  const getDataset = async (id: string) => {
    const d: Dataset = await getDatasetFromAPI(id);
    setDataset(d);
  };
  React.useEffect(() => {
    getDataset(id);
  }, []);

  const d = dataset.result;

  let notesSplit = ["", "", ""];
  if (_.has(d, "notes")) {
    const firstSplit = d.notes.split(". Volitelnými údaji jsou ")
    const secondSplit = firstSplit[0].split("Povinnými údaji pro tento typ právní formy je ")
    notesSplit = [
      secondSplit[0], //uvod
      secondSplit[1], //povinne
      firstSplit[1]   //volitelne
    ]
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          size="medium"
          variant="outlined"
          color="secondary"
          onClick={() => history.goBack()}
        >
          Zpět na vyhledávání
        </Button>
        {d && (
          <div>
            <Typography
              className={classes.title}
              component="h2"
              variant="subtitle1"
              gutterBottom
              // color="secondary"
            >
              {d.title}
            </Typography>

            <Typography
              className={classes.notes}
              component="div"
              variant="body1"
              gutterBottom
            >
              <p>
                {notesSplit[0]}
              </p>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className={classes.notesHeader}>Povinné údaje:</div>
                  <ul className={classes.notesCols}>
                    {notesSplit[1].split(", ").map((str, index) => (
                      <li key={index}>{str}</li>
                    ))}
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.notesHeader}>Volitelné údaje:</div>
                  <ul className={classes.notesCols}>
                    {notesSplit[2].split(", ").map((str, index) => (
                      <li key={index}>{str}</li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            </Typography>
            <dl>
              <DetailMeta
                heading="Klíčová slova sady:"
                text={d.tags.map(t => t.name).join(" | ")}
              />
              <DetailMeta
                heading="IRI datové sady:"
                text={
                  <Link href={window.location.href}>
                    {window.location.href}
                  </Link>
                }
              />
              <DetailMeta
                heading="Kontaktní bod:"
                text={
                  <Link href="mailto:dataor@msp.justice.cz">
                    dataor@msp.justice.cz
                  </Link>
                }
              />
              <DetailMeta
                heading="Poskytovatel:"
                text={`
                    ${d.publisher_name} `}
                optionalComponent={
                  <Link href={d.publisher_uri}>{d.publisher_uri}</Link>
                }
              />
              <DetailMeta heading="Identifikátor:" text={d.id} />
              <DetailMeta
                heading="Dokumentace:"
                text={
                  <Link href={window.location.href} target="_blank">
                    {window.location.href}.html
                      </Link>
                }
              />
              <DetailMeta
                heading="Periodicita aktualizace:"
                text={d.frequency && (frequency as any)[d.frequency]}
              />

              <DetailMeta
                heading="Územní pokrytí:"
                text={d.location && (location as any)[d.location].label}
              />
            </dl>
            <Typography
              className={classes.title}
              component="h2"
              variant="subtitle1"
              gutterBottom
            >
              Distribuce:
            </Typography>
            {!_.isNil(d.resources) && !_.isEmpty(d.resources) && (
              <Paper className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Soubor</TableCell>
                      <TableCell>Formát</TableCell>
                      <TableCell>Struktura</TableCell>
                      <TableCell>Podmínky užití</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {d.resources.map(r => (
                      <TableRow key={r.id}>
                        <TableCell>
                          <Link
                            classes={{ root: classes.noWrap }}
                            underline="always"
                            href={r.apiUrl}
                          >
                            {r.id}
                          </Link>
                        </TableCell>
                        <TableCell>{_.get(format, r.formatEnum, {}).label}</TableCell>
                        <TableCell>
                          {r.describedBy &&
                            <Link underline="always" href={r.describedBy}>
                              {r.describedByUrl}
                            </Link>
                          }
                        </TableCell>
                        <TableCell>
                          <Link underline="always" href={r.apiLicence}>
                            {r.license_link}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            )}
          </div>
        )}
        {!d && (
          <Typography
            className={classes.title}
            component="h2"
            variant="subtitle1"
            gutterBottom
            color="secondary"
          >
            ...
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
